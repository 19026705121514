var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "min-h-100vh bg-[#f8f8f8]" },
      [
        _c(
          "my-nav",
          {
            attrs: { title: "任务抽查结果记录", "self-back": true },
            on: { back: _vm.selfBack },
          },
          [
            _c(
              "div",
              {
                staticClass: "text-14px font-normal text-[#999]",
                attrs: { slot: "right" },
                on: { click: _vm.changeStatus },
                slot: "right",
              },
              [
                _c(
                  "span",
                  { class: [_vm.showAll ? "text-[#222]" : "text-current"] },
                  [_vm._v("全部")]
                ),
                _c("span", { staticClass: "text-[#222]" }, [_vm._v("/")]),
                _c(
                  "span",
                  { class: [_vm.showAll ? "text-current" : "text-[#222]"] },
                  [_vm._v("未读")]
                ),
              ]
            ),
          ]
        ),
        _vm.vanListShow
          ? _c(
              "van-list",
              {
                staticClass: "p-8px space-y-8px",
                attrs: {
                  finished: _vm.finished,
                  "finished-text": "- 没有更多数据了 -",
                  "immediate-check": false,
                },
                on: { load: _vm.load_more },
                model: {
                  value: _vm.pageLoading,
                  callback: function ($$v) {
                    _vm.pageLoading = $$v
                  },
                  expression: "pageLoading",
                },
              },
              _vm._l(_vm.pageData, function (item, index) {
                return _c(
                  "section",
                  {
                    key: index,
                    staticClass:
                      "bg-white rounded-[8px] p-12px pl-0 pr-16px section_main flex",
                    class: { active: item.status !== 3 },
                    on: {
                      click: function ($event) {
                        return _vm.goDetails(item)
                      },
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "w-56px h-full flex justify-center",
                    }),
                    _c("div", { staticClass: "flex-1" }, [
                      _c("div", { staticClass: "cus_diviedr section_top" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-16px font-normal leading-24px relative section_title",
                          },
                          [_c("span", [_vm._v(_vm._s(item.msgTitle))])]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-18px font-medium leading-26px mt-4px mb-8px relative",
                          },
                          [
                            _c("div", {
                              staticClass:
                                "absolute -left-24px transform -translate-x-16px top-2px w-24px h-24px",
                              class: [
                                item.status !== 3 ? "active_bell" : "gray_bell",
                              ],
                            }),
                            _c("span", [_vm._v(_vm._s(item.msg))]),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex justify-between items-center mt-8px text-12px",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "section_time leading-14px" },
                            [_vm._v(_vm._s(item.createTime))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "inline-flex items-center leading-14px",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "inline-flex items-baseline" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "section_seemore" },
                                    [_vm._v(_vm._s(item.opt))]
                                  ),
                                  _c("van-icon", {
                                    staticClass: "section_seemore transform",
                                    attrs: { name: "arrow" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }