<template>
  <div class="">
    <div class="min-h-100vh bg-[#f8f8f8]">
      <my-nav title="任务抽查结果记录" @back="selfBack" :self-back="true">
        <div class="text-14px font-normal text-[#999]" @click="changeStatus" slot="right">
          <span :class="[showAll?'text-[#222]':'text-current']">全部</span>
          <span class="text-[#222]">/</span>
          <span :class="[showAll?'text-current':'text-[#222]']">未读</span>
        </div>
      </my-nav>
      <!-- 标题以外的部分 -->
      <van-list class="p-8px space-y-8px" v-model="pageLoading" :finished="finished"
        finished-text="- 没有更多数据了 -" @load="load_more" :immediate-check="false" v-if="vanListShow">
        <!-- 每一条记录 -->
        <section v-for="(item, index) in pageData" :key="index" @click="goDetails(item)"
          class="bg-white rounded-[8px] p-12px pl-0 pr-16px section_main flex"
          :class="{active:item.status !== 3}">
          <!-- 左侧铃铛部分 -->
          <div class="w-56px h-full flex justify-center">
            <!-- 撑位置用的，右边的铃铛要定位定进来 -->
          </div>
          <!-- 右侧文字部分 -->
          <div class="flex-1">
            <div class="cus_diviedr section_top">
              <div class="text-16px font-normal leading-24px relative section_title">
                <span>{{item.msgTitle}}</span>
              </div>
              <div class="text-18px font-medium leading-26px mt-4px mb-8px relative">
                <div class="absolute -left-24px transform -translate-x-16px top-2px w-24px h-24px"
                  :class="[item.status!==3?'active_bell':'gray_bell']"></div>
                <span>{{item.msg}}</span>
              </div>
            </div>
            <div class="flex justify-between items-center mt-8px text-12px">
              <span class="section_time leading-14px">{{item.createTime}}</span>
              <span class="inline-flex items-center leading-14px">
                <!-- 查看详情按钮 -->
                <div class="inline-flex items-baseline">
                  <span class="section_seemore">{{item.opt}}</span>
                  <van-icon class="section_seemore transform" name="arrow" />
                </div>
                <!-- <van-icon class="section_seemore transform -translate-y-2px" name="arrow" /> -->
              </span>
            </div>
          </div>
        </section>
      </van-list>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
import mobile from '@/utils/mobile'
export default {
  data () {
    return {
      showAll: true,
      pageData: [],
      pageLoading: false, // 加载状态
      finished: false, // 是否加载
      condition: {
        page: 1,
        limit: 10,
        status: undefined,
        pageType: undefined
      },
      vanListShow: false// vant的list的v-if设置为false 防止提前加载
    }
  },
  computed: {
    pageType () {
      return this.$store.getters.getPageType
    }
  },
  mounted () {
    this.condition.pageType = this.pageType
    this.getData(true)
  },
  methods: {
    selfBack () {
      if (this.$route.query.backApp !== undefined) {
        mobile.backWeb()
      } else {
        this.$router.back()
      }
    },
    changeStatus () { // 切换全部和未读状态
      this.showAll = !this.showAll
      this.condition = this.$options.data().condition
      this.condition.pageType = this.pageType
      if (!this.showAll) { // 未读
        this.condition.status = [1, 2]
      }
      this.pageLoading = false
      this.finished = false
      this.pageData = []
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.getData(true)
    },
    load_more () { // 上拉加载
      this.condition.page += 1// 页数+1
      this.getData()
    },
    async getData (loading = false) { // 获取页面数据
      if (loading) {
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 999999
        })
      }
      const data = await this.$http.post(api.getSamplingResultsReq, this.condition, false)
      this.vanListShow = true
      this.$toast.clear()
      this.pageLoading = false
      this.pageData = this.pageData.concat(data.data)
      // 当加载完全部数据时，下滑load时不再继续加载数据
      if (this.pageData?.length >= data.total) {
        this.finished = true
      }
    },
    async goDetails (item) { // 点击去详情
      const obj = {
        msgId: item.msgId,
        status: 3
      }
      // 修改为已读
      await this.$http.post(api.setReadReq, obj, false)
      this.$router.push({ name: 'SampleResultsDetails', query: { checkNo: item.checkNo, msgId: item.msgId } })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
